import User from "apollo-react-icons/User";
import Autocomplete from "apollo-react/components/Autocomplete";
import DateRangePicker from "apollo-react/components/DateRangePicker";
import { TextField } from "apollo-react/components/TextField/TextField";
import Typography from "apollo-react/components/Typography";
import CryptoJS from "crypto-js";
import moment from "moment";
import React from "react";
import "../styles/customscroll.scss";
import { IDLE_LOGOUT_TIME } from "./constants";

const ACCESS_TOKEN = "access-token";
export const STUDY_SPECIAL_CHAR =
  /[^0-9a-zA-Z-._/\\[\]()&~!#$%^*+=><"'`;:,?|áéïóú\s]/gi;
export const INVALID_CHAR_MESSAGE =
  "The character you have entered is not valid";

export const getCookie = (key) => {
  const b = document.cookie.match(`(^|;)\\s*${key}\\s*=\\s*([^;]+)`);
  return b ? b.pop() : "";
};

// URL Related
export function getQueryParams(query) {
  const queryStrings = query.substr(1).split("&");
  const queryParams = {};
  queryStrings.forEach((element) => {
    const keyAndValue = element.split("=");
    // eslint-disable-next-line prefer-destructuring
    queryParams[keyAndValue[0]] = keyAndValue[1];
  });
  return queryParams;
}

export function getPathnameAndSearch(path) {
  const arr = path.split("?");
  return {
    pathname: arr[0],
    search: `?${arr[1]}`,
  };
}
export const getColumnPxWidth = (
  tableWidth = window.innerWidth,
  columnWidth = "50%"
) => {
  if (!String(columnWidth).includes("%")) {
    return columnWidth;
  }
  const percentage = Number(columnWidth.replace("%", ""));
  return (tableWidth * percentage) / 100;
};
export const getOverflowLimit = (width, pageSpace = 0, fontSize = 14) => {
  if (String(width).includes("%")) {
    width =
      ((window.innerWidth - Number(pageSpace)) *
        Number(width.replace("%", ""))) /
      100;
  }
  return Math.round((Number(width) * 1.91) / fontSize);
};
export const getInnerElOverflLimit = (tableWidth, columnWidth) => {
  const width = getColumnPxWidth(tableWidth, columnWidth);
  return getOverflowLimit(width);
};
export const matchAppUrl = () => {
  let appUrl = getCookie("user.app_url");
  if (appUrl) appUrl = decodeURIComponent(appUrl);
  return window.location.origin === appUrl;
};

export function getLastLogin() {
  const currentLogin = getCookie("user.last_login_ts");
  if (currentLogin === "first_time" || !currentLogin) return null;
  return moment
    .utc(moment.unix(currentLogin))
    .local()
    .format("DD-MMM-YYYY hh:mm A");
}

export const getDomainName = () => {
  const urlParts = window.location.hostname.split(".");
  return urlParts
    .slice(0)
    .slice(-(urlParts.length === 4 ? 3 : 2))
    .join(".");
};

export function deleteAllCookies() {
  const domain = getDomainName() || "";
  document.cookie.split(";").forEach((c) => {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(
        /=.*/,
        `=;expires=${new Date().toUTCString()};domain=${domain};Secure`
      );
  });
  return true;
}

export const getUserToken = () => {
  return getCookie("user.token");
};

export function getUserId() {
  return getCookie("user.id");
}

export const encrypt = (key) => {
  if (!key || !process.env.REACT_APP_ENCRYPTION_KEY) {
    return "Encryption key not found";
  }
  return CryptoJS.AES.encrypt(
    key,
    process.env.REACT_APP_ENCRYPTION_KEY
  ).toString();
};

export function getTenantId() {
  return getCookie("tenant");
}

export function setTenantId(Name) {
  document.cookie = `tenant = ${Name}; domain=${getDomainName()};secure=true;`;
}

export function getDomainPath() {
  const location = window.location.hostname;
  if (location?.includes("localhost")) {
    return "localhost";
    // eslint-disable-next-line
  } else {
    return "iqvia.com";
  }
}
// eslint-disable-next-line
export function apiKeyCookieGenerator() {
  const cookie = getCookie("api-key");
  if (!cookie) {
    const apikey = encrypt(process.env.REACT_APP_API_KEY);
    document.cookie = `api-key=${apikey}; domain=${getDomainPath()};secure=true;`;
  }
  return getCookie("api-key") ? getCookie("api-key") : "";
}
// eslint-disable-next-line
export function accessTokenCookieGenerator() {
  const cookie = getCookie(ACCESS_TOKEN);
  const userId = getCookie("user.id");
  if (!cookie) {
    const encryptedUserId = encrypt(userId);
    if (
      encryptedUserId !== "" &&
      encryptedUserId !== "Encryption key not found" &&
      encryptedUserId !== undefined &&
      encryptedUserId !== null
    ) {
      document.cookie = `access-token=${encryptedUserId}; domain=${getDomainPath()}; secure=true;`;
    }
  }
  return getCookie(ACCESS_TOKEN) ? getCookie(ACCESS_TOKEN) : "";
}

export function setCookiesOnWeb() {
  const cookie = getCookie("user.server_url");
  if (!cookie) {
    document.cookie = `user.server_url=${
      process.env.REACT_APP_API_URL
    }; domain=${getDomainPath()}; secure=true; `;
  }
}

export function getUserInfo() {
  return {
    fullName: decodeURIComponent(`${getCookie("user.first_name")} 
                                  ${getCookie("user.last_name")}`),
    firstName: getCookie("user.first_name"),
    lastName: getCookie("user.last_name"),
    userEmail: decodeURIComponent(getCookie("user.email")),
    lastLogin: getLastLogin(),
    user_id: getUserId(),
  };
}

let timerId;
export const debounceFunction = (func, delay) => {
  // Cancels the setTimeout method execution
  clearTimeout(timerId);
  // Executes the func after delay time.
  timerId = setTimeout(func, delay);
};

export const titleCase = (str) => {
  const splitStr = str
    .toLowerCase()
    .split(" ")
    .filter((value) => value.length && value !== "\n");
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
};

export const compareStrings = (accessor, sortOrder) => {
  return (rowA, rowB) => {
    if (!rowA[accessor]) {
      return 1;
    }
    if (!rowB[accessor]) {
      return -1;
    }
    const stringA = rowA[accessor].toString().toUpperCase();
    const stringB = rowB[accessor].toString().toUpperCase();
    if (sortOrder === "asc") {
      if (stringA < stringB) {
        return -1;
      }

      if (stringA > stringB) {
        return 1;
      }

      return 0;
    }
    if (stringA < stringB) {
      return 1;
    }

    if (stringA > stringB) {
      return -1;
    }

    return 0;
  };
};

export const compareNumbers = (accessor, sortOrder) => {
  return (rowA, rowB) => {
    if (!rowA[accessor]) {
      return 1;
    }
    if (!rowB[accessor]) {
      return -1;
    }
    const numberA = rowA[accessor];
    const numberB = rowB[accessor];

    if (sortOrder === "asc") {
      return numberA - numberB;
    }
    return numberB - numberA;
  };
};

/* eslint-disable react/display-name */
export const compareDates = (accessor, sortOrder) => {
  return (rowA, rowB) => {
    let result;
    const valueA = rowA[accessor];
    const valueB = rowB[accessor];

    if (!valueA && valueB) {
      result = -1;
    } else if (!valueB && valueA) {
      result = 1;
    } else if (!valueA && !valueB) {
      result = 0;
    }

    if (result === undefined) {
      const dateA = moment(valueA);
      const dateB = moment(valueB);

      if (!dateA.isValid() && dateB.isValid()) {
        result = -1;
      } else if (!dateB.isValid() && dateA.isValid()) {
        result = 1;
      } else if (!dateA.isValid() && !dateB.isValid()) {
        result = 0;
      } else if (dateA.isBefore(dateB)) {
        result = -1;
      } else if (dateB.isBefore(dateA)) {
        result = 1;
      } else {
        result = 0;
      }
    }
    return sortOrder === "asc" || result === 0 ? result : -result;
  };
};

// eslint-disable-next-line consistent-return
/*eslint-disable */
export const inputAlphaNumeric = (e, callback) => {
  const value = e.target.value
    ? e.target.value.replace(/[^0-9a-zA-Z-_()#*/,.[\]\s]/gi, "")
    : "";
  /*eslint-disable */
  if (e.target.value !== value) {
    e.target.value = value;
  }

  if (typeof callback === "function") {
    return callback(value);
  }
};

// eslint-disable-next-line consistent-return
export const inputAlphaNumericWithUnderScore = (e, callback) => {
  const value = e.target.value
    ? e.target.value.replace(/[^0-9a-zA-Z_-\s]+/gi, "")
    : "";

  if (e.target.value !== value) {
    e.target.value = value;
  }

  if (typeof callback === "function") {
    return callback(value);
  }
};

// eslint-disable-next-line consistent-return
export const inputAlphaNumericWithUnderScoreOnly = (e, callback) => {
  const value = e.target.value ? e.target.value.replace(/\W+/gi, "") : "";

  if (e.target.value !== value) {
    e.target.value = value;
  }

  if (typeof callback === "function") {
    return callback(value);
  }
};

// eslint-disable-next-line consistent-return
export const inputAlphaNumericOnly = (e, callback) => {
  const value = e.target.value
    ? e.target.value
        .trimStart()
        .replace(/([^0-9a-zA-Z-_()/&.[\]\s])+/gi, "")
        .replace(/([\s])+/gi, " ")
    : "";

  if (e.target.value !== value) {
    e.target.value = value;
  }

  if (typeof callback === "function") {
    return callback(value);
  }
};

export const studySpecialChar = (e, callback) => {
  let value = e.target.value
    ? e.target.value.trimStart().replace(STUDY_SPECIAL_CHAR, "")
    : "";
  let isValid = true;
  if (e.target.value !== value) {
    value = e.target.value;
    isValid = false;
  }

  if (typeof callback === "function") {
    return callback(value, isValid);
  }
};

export const inputNumericOnly = (e, callback) => {
  const value = e?.target?.value
    ? e?.target?.value
        ?.trimStart()
        ?.replace(/(\D)+/gi, "")
        ?.replace(/([\s])+/gi, " ")
    : "";

  if (e?.target?.value !== value) {
    e.target.value = value;
  }

  if (typeof callback === "function") {
    return callback(value);
  }
};

export const createAutocompleteFilter =
  (source) =>
  ({ accessor, filters, updateFilterValue }) => {
    const ref = React.useRef();
    const [height, setHeight] = React.useState(0);
    const [isFocused, setIsFocused] = React.useState(false);
    const value = filters[accessor];

    React.useEffect(() => {
      const curHeight = ref?.current?.getBoundingClientRect().height;
      if (curHeight !== height) {
        setHeight(curHeight);
      }
    }, [value, isFocused, height]);

    return (
      <div
        style={{
          minWidth: 160,
          maxWidth: 200,
          position: "relative",
          height,
        }}
      >
        <Autocomplete
          style={{ position: "absolute", left: 0, right: 0 }}
          value={
            value
              ? value.map((label) => {
                  if (label === "") {
                    return { label: "blanks" };
                  }
                  return { label };
                })
              : []
          }
          name={accessor}
          source={source}
          onChange={(event, value2) => {
            updateFilterValue({
              target: {
                name: accessor,
                value: value2.map(({ label }) => {
                  if (label === "blanks") {
                    return "";
                  }
                  return label;
                }),
              },
            });
          }}
          fullWidth
          multiple
          size="small"
          limitChips={1}
          filterSelectedOptions={false}
          enableVirtualization
          clearOnBlur={false}
          matchFrom="any"
          showSelectAll
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          ref={ref}
          noOptionsText="No matches"
        />
      </div>
    );
  };

export const TextFieldFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      fullWidth
      margin="none"
      size="small"
    />
  );
};

export const IntegerFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      type="number"
      style={{ width: 74 }}
      margin="none"
      size="small"
    />
  );
};

export const Box = ({ children }) => {
  return (
    <span className="label" variant="body2">
      {children}
    </span>
  );
};

export const Label = ({ children }) => {
  return (
    <Typography className="label" variant="body2">
      {children}
    </Typography>
  );
};
export const Value = ({ children }) => {
  return (
    <Typography className="value b-font" variant="body2">
      {children}
    </Typography>
  );
};

// overwriting numberSearchFilter from react-apollo

export const numberSearchFilter = (accessor) => {
  return function (row, filters) {
    const rowVal = parseInt(row[accessor], 10);
    const filterVal = parseInt(filters[accessor], 10);
    if (!filters[accessor]) {
      return true;
    }

    if (!row[accessor]) {
      return false;
    }
    return rowVal === filterVal;
  };
};
export const dateFilterCustom = (accessor) => (row, filters) => {
  if (!filters[accessor]) {
    return true;
  }
  if (!row[accessor]) {
    return false;
  }
  const date = moment(row[accessor]);
  const fromDate = moment(filters[accessor][0], "YYYY-MM-DD");

  const toDate = moment(filters[accessor][1], "YYYY-MM-DD").endOf("day");

  return (
    (!fromDate.isValid() || date.isAfter(fromDate)) &&
    (!toDate.isValid() || date.isBefore(toDate))
  );
};

export const DateFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <div style={{ minWidth: 180 }} data-testid="date-filter">
      <div style={{ position: "absolute", top: 0, paddingRight: 4 }}>
        <DateRangePicker
          value={filters[accessor] || [null, null]}
          name={accessor}
          onChange={(value) =>
            updateFilterValue({
              target: { name: accessor, value },
            })
          }
          startLabel=""
          endLabel=""
          placeholder=""
          fullWidth
          margin="none"
          size="small"
        />
      </div>
    </div>
  );
};

export const createStringArraySearchFilter = (accessor) => {
  return (row, filters) =>
    !Array.isArray(filters[accessor]) ||
    filters[accessor].length === 0 ||
    filters[accessor].some(
      (value) => value.toUpperCase() === row[accessor]?.toUpperCase()
    );
};

export const createStringArrayIncludedFilter = (accessor) => {
  return (row, filters) =>
    !Array.isArray(filters[accessor]) ||
    filters[accessor].length === 0 ||
    filters[accessor].some((value) =>
      row[accessor]?.toUpperCase().includes(value?.toUpperCase())
    );
};

export const EmptyTableContent = ({ page }) => {
  return (
    <>
      <User className="user-rocket-icon" />
      <Typography variant="title1" className="empty-grey">
        Nothing to See Here
      </Typography>
      <Typography variant="title2" className="empty-subtitle">
        This {page} has no assignments
      </Typography>
    </>
  );
};

export const Capitalize = (str) => {
  return str && str.charAt(0).toUpperCase() + str.slice(1);
};

export const createFilterList = (list) => {
  return Array.from(
    new Set(list?.map((r) => ({ label: r })).map((item) => item.label))
  )
    .map((label) => {
      return { label };
    })
    .sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
};

export const createSourceFromKey = (tableRows, key) => {
  return Array.from(
    new Set(
      tableRows
        ?.map((r) => ({ label: Capitalize(r[key]) }))
        .map((item) => item.label)
    )
  )
    .map((label) => {
      return { label };
    })
    .sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
};

export const getAppUrl = (app) => {
  let appUrl;
  switch (app) {
    case "CDI":
      appUrl =
        process.env.REACT_APP_CDI_URL ||
        `${window.location.protocol}//${window.location.hostname}:3000`;
      break;

    case "CDM":
      appUrl =
        process.env.REACT_APP_CDM_URL ||
        `${window.location.protocol}//${window.location.hostname}:3000`;
      break;

    case "CA":
      appUrl =
        process.env.REACT_APP_CA_URL ||
        `${window.location.protocol}//${window.location.hostname}:3000`;
      break;
    case "DRM":
      appUrl =
        process.env.REACT_APP_DRM_URL ||
        `${window.location.protocol}//${window.location.hostname}:3000`;
      break;
    case "IETMF":
      appUrl =
        process.env.REACT_APP_IETMF_URL ||
        `${window.location.protocol}//${window.location.hostname}:3000`;
      break;

    case "DSW":
      appUrl =
        process.env.REACT_APP_DSW_URL ||
        `${window.location.protocol}//${window.location.hostname}:3000`;
      break;

    default:
      appUrl = `${window.location.protocol}//${window.location.hostname}:3000`;
      break;
  }
  // eslint-disable-next-line consistent-return
  return appUrl;
};
export const goToApp = (path, newTab = false) => {
  if (newTab) {
    window.open(path, "_blank");
  } else {
    window.location.href = path;
  }
};

export const setIdleLogout = (logout) => {
  let time;
  // DOM Events
  function resetTimer() {
    clearTimeout(time);
    time = setTimeout(() => {
      logout();
    }, IDLE_LOGOUT_TIME);
  }
  document.onmousemove = resetTimer;
  document.addEventListener("keydown", resetTimer);
  document.addEventListener("keyup", resetTimer);
  window.onload = resetTimer;
};
